import logo from './logo.svg';
import React, { useLayoutEffect } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import Home from "./home";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import Ciber from "./screens/ciber";
import Soc from "./screens/soc";
import Asesoria from "./screens/asesoria";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};


function App() {
  return (
    <div className="App">
      <Router>
        <Wrapper>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='ciber' element={<Ciber/>}/>
          <Route path='soc' element={<Soc/>}/>
          <Route path='asesoria' element={<Asesoria/>}/>
        </Routes>
        </Wrapper>
      </Router>
    </div>
  );
}

export default App;