import { Col } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";

export const ProjectCard = ({ title, imgUrl, href }) => {
  return (

       <Col size={12} sm={6} md={4}>
         <div className="proj-imgbx">
           <img src={imgUrl} />
           <div className="proj-txtx">
             <h4>{title}</h4>
             <br></br><br/>
              <Link to={href} className="link" >Ver Mas</Link>
            </div>
          </div>
        </Col>
   
  )
}
