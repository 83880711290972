import React from 'react'
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/LOGO 1.jpg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

const BannerSoc = () => {
  return (
    <section className="banner-soc">
            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={8}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h1>{``} Servicio de Monitoreo SOC</h1>
                                    <br/><br/><br/><br/>
                                    <br/><br/><br/><br/>
                                    <br/><br/><br/><br/>
                                    <p></p>
                                </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
  )
}

export default BannerSoc