import emailjs from '@emailjs/browser';
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'

const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_60h76ir';
    const templateId = 'template_5s7nx9l';
    const publicKey = 'uX28rxuCmdC9uG7dd';

    // Create a new object that contains dynamic template params
    const templateParams = {
      user_name: name,
      user_email: email,
      message: message,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
        
        Swal.fire({
          title: "¡Listo!",
          text: "El mensaje ha sido enviado",
          icon: "success",
          confirmButtonText: "Aceptar"
        })

      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (

    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Comunicate con nosotros!</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={name} placeholder="Coloque su nombre" onChange={(e) => setName(e.target.value)} required />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={message} placeholder="Coloque su mensaje" onChange={(e) => setMessage(e.target.value)} required ></textarea>
                      <button type="submit"><span>Enviar</span></button>
                    </Col>
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact