import React from 'react';
import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";
import Contact from "../components/Contact";
import BannerCiber from "../components/BannerCiber";
import CiberService from "../components/CiberService";
import '../App1.css';

const Ciber = () => {
  return (
    <div>
      <NavBar/>
      <BannerCiber />
      <CiberService/>
      <Contact />
      <Footer />
    </div>
  );
};

export default Ciber;
