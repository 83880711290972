import React from 'react';
import '../App1.css';
import img from "../assets/img/2PROTECTION 2300X800.png";
import img1 from "../assets/img/SOC.jpg"
import img2 from "../assets/img/Diseño_sin_título-removebg-preview.png";
import paloma from "../assets/img/marca-de-verificacion.png";
import soci from "../assets/img/laderos/1.png"

const SocService = () => {
  return (
    
    <section className='completos'>

        <div className='soc'>

            <br/>
            <p className='primerPS'>
            Detecta y responde a tiempo ante amenazas e incidentes de ciberseguridad integrando tu infraestructura tecnológica a los servicios administrados de un SOC, tu seguridad es nuestra prioridad.
            </p>
            <div className="soc-icons">
              <div className="icon1">
                <h4>Monitoreo 24 x 7</h4>
                <div className='as'>
                    <br/><p>Protección las 24 horas del día los 365 días del año.</p><br/><br/>
                </div>
              </div>
              <div className="icon1">
                <h4>Eficaz y eficiente</h4>
                <div className='as'>
                <br/><p>Reduce el tiempo de detección en 8x y el tiempo de respuesta en 20x.</p><br/>
                <br/></div>
              </div>
              <div className="icon1">
                <h4>Uso de IA y ML</h4>
                <div className='as'>
                <br/><p>Uso de OPEN XDR, EDR y herramientas basadas en Inteligencia Artificial y Machine Learnig.</p><br/>
                </div>
              </div>
              <div className="icon1">
                <h4>A la medida de tu negocio</h4>
                <div className='as'>
                <br/><p>Implementación de plan de respuesta a incidentes de acuerdo al contexto de tu negocio.</p><br/>
                </div>
              </div>
              <div className="icon1">
                <h4>Customer Succes Manager</h4>
                <div className='as'>
                <br/><p>Asignación de un CSM responsable del habilitamiento, escalación, reportes y comunicación continua.</p><br/>
                </div>
              </div>
              <div className="icon1">
                <h4>Generacion de Reportes</h4>
                <div className='as'>
                <br/><p>Reportes sobre el cumplimiento normativo.</p><br/><br/>
                </div>
              </div>
            </div>

              <div className='contenedorlista' >

                <div className='lista'>
                  <p>Soluciones</p>
                  <ul>
                    <li><img src={paloma}/>Atencion de eventos e incidentes.</li>
                    <li><img src={paloma}/>Gestión de eventos e incidentes de seguridad.</li>
                    <li><img src={paloma}/>Monitoreo 24/7 los 365 días del año por analistas de seguridad certificados.</li>
                    <li><img src={paloma}/>Sesiones mensuales para reportes y seguimiento a incidentes.</li>
                    <li><img src={paloma}/>Uso de metodologías de MITRE Attack y Cyber Kill </li>
                    <li><img src={paloma}/>Chain para gestión de incidentes.</li>
                    <li><img src={paloma}/>Generación de reportes para cumplimiento de normativas y certificaciones.</li>
                  </ul>
                </div>
                <div className='listado'>
                  <ul>
                    <li><img src={soci}/></li>
                  </ul>
                </div>
              </div>

        </div>

    </section>

  )
}

export default SocService