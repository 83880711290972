import React from 'react';
import '../App1.css';
import img from "../assets/img/2PROTECTION 2300X800.png";
import img1 from "../assets/img/Confidencialidad.png"
import img2 from "../assets/img/Diseño_sin_título-removebg-preview.png";
import paloma from "../assets/img/escudo-de-escudo.png";
import asi from "../assets/img/laderos/3.png"
import { Container } from 'react-bootstrap';

const SocService = () => {
  return (
    
    <section className='completoss'>

        <div className='asesoria'>

            <br/>
            <p className='primerPSS'>
            La ciberseguridad es un proceso continuo de planificación, implementación, supervisión y mejora de las medidas de seguridad para proteger los datos contra los ataques cibernéticos.
            </p>

            <img src={img1} className='triada'/>

              <div className='contenedorlista' >

                <div className='lista'>
                  <p className='sol' >Soluciones</p>
                  <ul>
                    <li><img src={paloma}/>Creacion de estrategias de seguridad para tu negocio.</li>
                    <li><img src={paloma}/>Seguimiento de cumplimiendo de certificacion.</li>
                    <li><img src={paloma}/>Pruebas de ingenieria social.</li>
                    <li><img src={paloma}/>Pláticas de concientización a altos directivos y usuarios.</li>
                  </ul>
                </div>
                <div><ul></ul></div>
                <div className='listado'>
                  <ul>
                    <li><img src={asi}/></li>
                  </ul>
                </div>
              </div>

        </div>

    </section>

  )
}

export default SocService