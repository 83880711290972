import React from 'react';
import '../App1.css';
import img1 from "../assets/img/iconss/1.png"
import img2 from "../assets/img/iconss/2.png"
import img3 from "../assets/img/iconss/3.png"
import img4 from "../assets/img/iconss/4.png"
import img5 from "../assets/img/iconss/5.png"
import img6 from "../assets/img/iconss/6.png"
import img7 from "../assets/img/iconss/7.png"
import img8 from "../assets/img/iconss/8.png"
import paloma from "../assets/img/marca-de-verificacion.png";
import cib from "../assets/img/laderos/2.png"

const CiberService = () => {
  return (
    <section className='completo'>

        <div className='cibers'>

            <p className='primerP'>
            Contamos con soluciones integrales de ciberseguridad enfocadas en la mitigación de los riesgos cibernéticos que
            puedan presentarse en su organización. Estos servicios apoyan en la identificación, protección, detección, prevención y
            recuperación de sus activos digitales.
            </p>
            <div className="ciber-icons">
              <div className="icon"><img src={img1}/><p>Next Generation Firewall.</p></div>
              <div className="icon"><img src={img2}/><p>Protección de Correo Electrónico.</p></div>
              <div className="icon"><img src={img3}/><p>Redes Seguras.</p></div>
              <div className="icon"><img src={img4}/><p>Protección de EndPoints.</p></div>
              <div className="icon"><img src={img5}/><p>Monitoreo de Red.</p></div>
              <div className="icon"><img src={img6}/><p>Prevención de Perdida de Datos.</p></div>
              <div className="icon"><img src={img7}/><p>Seguridad de Aplicaciones Web.</p></div>
              <div className="icon"><img src={img8}/><p>Threat Hunting.</p></div>
            </div>

              <div className='contenedorlista' >

                <div className='lista'>
                  <p>Beneficios</p>
                  <ul>
                    <li><img src={paloma}/>Aumenta la productividad de tu negocio.</li>

                    <li className='xd'><img src={paloma}/>Administracion de tecnología y sistemas de ciberseguridad</li>
                    <li className='xd'><img/> de última generación como servicio.</li>

                    <li className='xd'><img src={paloma}/>El equipo de expertos de 2PROTECTION será responsable de la </li>
                    <li className='xd'><img/>administración y gestión de la infraestructura y sistemas</li>
                    <li><img/> de ciberseguridad.</li>

                    <li><img src={paloma}/>De la mano de personas altamente calificadas.</li>
                    <li><img src={paloma}/>Tareas diarias de mantenimiento como el rastreo de amenazas </li><li><img/>emergentes y acciones para defenderse de ellas.</li>

                    <li><img src={paloma}/>Facilita el crecimiento en capacidad y servicios.</li>
                  </ul>
                </div>
                <div className='listado'>
                    <li><img src={cib}/></li>
                </div>
              </div>

        </div>

    </section>
  );
};

export default CiberService;