import React from 'react';
import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";
import Contact from "../components/Contact"
import BannerSoc from "../components/BannerSoc"
import SocService from "../components/SocService";
import '../App1.css';

const Soc = () => {
  return (
    <div>
      <NavBar />
      <BannerSoc />
      <SocService />
      <Contact />
      <Footer />
    </div>
  );
};

export default Soc;