import React from 'react';
import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";
import Contact from "../components/Contact";
import AsesoriaBanner from "../components/AsesoriaBanner";
import AsesoriaService from "../components/AsesoriaService";
import '../App1.css';

const Asesoria = () => {
  return (
    <div>
      <NavBar />
      <AsesoriaBanner />
      <AsesoriaService />
      <Contact />
      <Footer />
    </div>
  );
};

export default Asesoria;